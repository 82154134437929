.login-page{
}
.login-page-mobile{
    background-color: white;
    width: 95vw;
}
.signup-page{

}
.signup-page-mobile{
    width: 95vw;

}
.ls-component{
    padding-right: min(40px, 5%);
    padding-left: min(40px, 5%);
    padding-top: min(50px, 2vw);
    padding-bottom: min(50px, 5vw);
    border-radius: 7px;
    background-color: white;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
.ls-component-mobile{
    box-shadow:none !important;
}

.MuiSelect-select{
    padding: 10px 14px !important;
}
