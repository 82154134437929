.dashboard-content{
    /* padding: .2em 1.4em !important; */

}

.dcontent-wrap{
}
.dashobard-action{
    display: inline-block;
    vertical-align: middle;
    border:solid 1px gainsboro;
    padding:18px;
    max-width: 520px;
    /* min-width: 440px; */
    width: 100%;

    min-height: 210px;
    cursor: pointer;
    transition: 0.2s;
    margin: 10px 12px 10px 0px;
    text-decoration: none;
    color:black;
    border-radius: 1.2em;

}

.dashobard-action:hover{
    border:solid 1px rgb(164, 164, 164);
}


.dashobard-action-m{
    display: inline-block;
    vertical-align: middle;
    border:solid 1px gainsboro;
    padding:10px;
    max-width: 520px;
    width: 100%;

    min-height: 210px;
    cursor: pointer;
    transition: 0.2s;
    margin: 10px 12px 10px 0px;
    text-decoration: none;
    color:black;
    border-radius: 1.2em;

}

.dashobard-action-m:hover{
    border:solid 1px rgb(164, 164, 164);
}

.dashboard-btn{
    /* margin-top:14px; */
    width:140px;
    height:20px !important;
    /* position: absolute !important; */
}

.cancel-sub-b{
    background-color: white;
    padding:16px;
    border-radius: 8px;
    border: solid 2px gray;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.2s;
    display: flex;
}

.cancel-sub-b:hover{
    border: solid 2px black;
    opacity: 1;
}