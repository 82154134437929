*{
  box-sizing: border-box;
} 
div {
  box-sizing: border-box;
}
p,h1,h2,h3,h4{
  margin:0px;
}
input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2); /* IE */
  -moz-transform: scale(1.2); /* FF */
  -webkit-transform: scale(1.2); /* Safari and Chrome */
  -o-transform: scale(1.2); /* Opera */
  transform: scale(1.2);
  padding: 10px;
  margin-right: 10px;
  margin-left: 4px;
}
.page{
  padding-top: 14px;
}
.dp-doc-web{
  background-color: rgb(240,242,245);
  overflow: hidden;
}
.page-box-1{
  background-color: white;
  padding: 1.2em 2.2em ;
  border-radius: 1.2em;
  margin-left: 1.2em;
  margin-right: 1.2em;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.page-box-1-mobile{
  background-color: white;
  padding: 1.2em 1em !important;
  border-radius: 1em;
  margin-left: 0.9em !important; 
  margin-right: 0.9em !important; 
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.page-box-2{
  background-color: white;
  padding: 1.4em 1.4em !important;
  border-radius: 0.6em;
  margin-left: 1em;
  margin-right: 1em;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.red-btn1{
 background-color:rgb(223,32,39) !important;
}
.red-btn1:hover{
  background-color:rgb(207, 25, 31) !important;
}
.btn1{
  width: 100%;
  border-radius: 6px;
  background-color: rgb(37, 37, 37);
  color: white;
  border:none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-weight: 800;
  font-size: 1.1rem;
  transition: 0.2s;
  max-height: 50px;
  min-height: 50px;
  text-decoration: none;
  text-align: center;
  padding:14px 22px;
}

.btn1:hover{
  background-color: rgb(0, 0, 0);
  color: rgb(246, 247, 255);
}

.btn1-second{
  background-color: rgb(241, 241, 241);
  color: rgb(37, 37, 37) !important;
}
.btn1-second:hover{
  background-color: rgb(218, 218, 218);
}


.btn1-disabled{
  pointer-events: none;
  background-color: rgb(182, 182, 182);
}
.btn1-load{
  background-color: rgb(50, 50, 50);
  pointer-events: none;
}
.li1{
  color: rgb(66, 66, 66);
  text-decoration: none;
  cursor: pointer;
}
.li1:hover{
  color: rgb(59, 0, 73);
}

.ititle{
  font-size: 0.85rem;
  margin-bottom: 6px;
}

.header-comp{
  height: 72px;
  border-bottom:solid 1px gainsboro;
  padding: 1em 2em;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
}
.header-comp-mobile{
  height: 72px;
  padding: 0.5em 1em;
  border-bottom:solid 1px gainsboro;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.hrd-login-control .hbtn{
  margin-left: 0.5em;
  width: 180px;
}   

.hrd-login-control .hbtn-mobile{
  margin-left: 0.5em;
  width: 94px;
  padding:8px 18px;
  font-size: 0.94rem;
}   
.btn-5{
  display: flex;
  border-radius: 5px;
  background-color: rgb(248, 248, 248);
  padding: 8px 12px;
  transition: 0.2s;
  cursor: pointer;
}
.btn-5:hover{
  background-color: rgb(225, 225, 225);
}


.hdr-sec-btn{
  border: none;
  padding: 1em 1em;
  margin: 0px 0.3em;
  background-color: white;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 6px;
  font-size: 0.9rem;
}
.hdr-sec-btn-m{
  border: none;
  padding: 1em 1em;
  background-color: white;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 6px;
  font-size: 0.9rem;
  width: 100px !important;
}
.hdr-sec-btn-m:hover{
  background-color: rgb(238, 238, 238);
}
.hdr-sec-btn:hover{
  background-color: rgb(238, 238, 238);
}
.go-to-dboard-btn{
  text-decoration: none;
  text-align: center;
  color:black;
  font-weight: 500 !important;
  width: 140px;
  height: 40px;
  padding: 10px 2px;
}
.user-header{
  background-color: red;

}

.user-panel{
  top:0;
  left: auto;
  right: 0;
  height: 44px;
  width: fit-content;
  display: flex;
  justify-content: right;
  padding:6px 18px;
  margin:4px;
  border-radius: 20px;
  transition: 0.2s;
  z-index: 11;
}

.user-panel:hover{
  background-color: rgba(0, 0, 0, 0.05);
}


.panel-btn{
  padding:4px;
  cursor: pointer;
  border-radius: 20px;
  background-color:transparent;
  transition: 0.2s;
}
.panel-btn:hover{
  background-color:white;

}

.user-control-panel{
  width: 320px;
  overflow: hidden;
  border-radius: 4px;
  background-color: white;  
  padding-bottom: 16px;
}


.btn2{
  transition: 0.2s;
  background-color: white;
  cursor: pointer;
  min-width: 100%;
  text-decoration: none;
}

.btn2:hover{
  background-color: rgb(244, 249, 251);
}


.user-photo {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background-color:rgb(180, 204, 228);
  color:rgb(24, 56, 89);
  cursor: pointer;
  overflow: hidden;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-photo:hover{
  background-color:rgb(151, 184, 216);
  color:rgb(20, 52, 84);
}

.header-logo-mobile{
  font-size: 0.8rem;
  font-weight: 800;
}
.header-logo{
  font-size: 1.8rem;
  font-weight: 800;
}