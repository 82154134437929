.info-block-left{
    background-color: rgb(250, 250, 250);
    color: rgb(50, 50, 50);
    padding: 28px 16px 50px 16px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: 0.3s;
    cursor: pointer;
}
.info-block-left:hover{
    background-color: rgb(50, 50, 50);
    color:white;
}

.info-block-bl-left{

    background-color: rgb(50, 50, 50);
    color:white;
    padding: 28px 16px 50px 16px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: 0.3s;
}
.info-block-bl-left:hover{
    background-color: rgb(250, 250, 250);
    color: rgb(50, 50, 50);
}


.info-block-right{
    background-color: rgb(250, 250, 250);
    color: rgb(50, 50, 50);
    padding: 28px 16px 50px 16px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: 0.3s;
}
.info-block-right:hover{
    background-color: rgb(50, 50, 50);
    color:white;
}