.landing-header{
    padding: 1.5em 1em;
    background-color: white;
}
.wlogin-actions .btn1{
    font-size: 1rem;
    margin: 6px 8px 0px 0px;
}
.slogan-1{
    font-size: 4.6rem;
    font-weight: 800;
}
.slogan-1-mobile{
    font-size: 3rem;
    font-weight: 800;
}
.lang-btn{
    display:flex;
    align-items:center;
    justify-content:center;
    width:90px;
    height:80px;
    border:solid 1px gainsboro;
    border-radius:9px;
    transition: 0.2s;
    cursor: pointer;
    text-align: center;
    margin: 0px 6px;
}
.lang-btn:hover{
    border:solid 1px rgb(59, 59, 59);
}
.pay-method-dd{
    text-decoration: none;
    /* cursor: pointer; */
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: 20px; 
    background-color:rgba(0,0,0,0.03);
}